window.require = require
window.jQuery = window.$ = require('jquery')

require('shiner')
var tri = require('tripartite')
var officerDetails = require('./officer-panel.tri')

var Dialog = require('ei-dialog')

var moment = require('moment');
moment().format();

/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

require('page-editor').configure()

var aboutSlider = $('.page-about .img-slider .slide').shiner()

$('.page-about .img-slider .left-arrow').click(function(evt) {
	aboutSlider.previous()
})

$('.page-about .img-slider .right-arrow').click(function(evt) {
	aboutSlider.next()
})

$('.page-fallen-officers .officer').click(function(evt) {
	evt.preventDefault()
	$.get('/officer-details/' + $(this).attr('data-officer-id')).done(function(data) {
		data.endOfWatch = moment(data.endOfWatch).format('MM/DD/YYYY')
		var content = officerDetails(data)
		var diag = new Dialog({
			title: data.firstName + ' ' + data.lastName,
			body: content,
			buttons: [
				{
					classes: 'btn btn-cancel blue-button',
					label: 'Close'
				}
			]
		})
		diag.open()
	})
})

var media = null;

var close = function() {
	if(media) {
		media.find('video').get(0).pause();
		media.remove();
		media = null;
	}
	$('.big-detailed-img').remove();
};

$('.pic-frame').on('click', function(evt) {
	if(evt.movieOpen) {
		return
	}
	evt.movieOpen = true
	evt.preventDefault();
	var urlPart = $(this).attr('data-video');
	if(urlPart) {
		media = $('<div class="movie-container no-show"><div class="movie-box"><video poster="/vrsc/123/img/white-poster.jpg" \
				controls="controls" preload="none" autoplay="autoplay">\
			<!-- MP4 for Safari, IE9, iPhone, iPad, Android, and Windows Phone 7 -->\
			<source type="video/mp4" src="' + urlPart + '.mp4" />\
			<!-- WebM/VP8 for Firefox4, Opera, and Chrome -->\
			<source type="video/webm" src="' + urlPart + '.webm" />\
			<!-- Ogg/Vorbis for older Firefox and Opera versions -->\
			<source type="video/ogg" src="' + urlPart + '.ogv" />\
			</video><div class="close">close</div></div></div>');

		$('body').append(media);
		var player = media.find('video').get(0);
		var movieBox = media.find('.movie-box');
	}
	else {
		urlPart =  $(this).attr('data-details')
		if(urlPart) {
			window.location = urlPart
		}
	}
//		setTimeout(function() {
//			var videoViewportWidth = (player.videoWidth / player.videoHeight) * movieBox.height();
//			var rightOffset = ((movieBox.width() - videoViewportWidth) / 2) + 15;
//			media.find('.close').css('right', rightOffset + 'px').show();
//		}, 1000);

	media.on('click', '.close', function(evt) {
		close();
	});

	setTimeout(function() {
		media.removeClass('no-show');
	}, 1);
});

$('body').keydown(function(evt) {
	if(evt.keyCode == 27) {
		close();
	}
});

var menuUpdateDone = false

$('.menu-bar a').each(function() {
	if(!menuUpdateDone && $(this).attr('href') == window.location.pathname) {
		menuUpdateDone = true
		$(this).closest('li').addClass('current')
	}
})

$('.tribar').click(function(evt) {
	evt.preventDefault()
	$('body').addClass('open')
})